import { css, cx } from "@emotion/css";
import axios from "axios";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { WebcamModal } from "../../components/Common/modal";
import env from "../../utils/env";

const Home = () => {
  const [file, setFile] = useState();
  const [inputKey, setInputKey] = useState();
  const [visiable, setVisiable] = useState();
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [aiResponse, setAiResponse] = useState();
  const onChange = (e) => {
    if (e.target.files[0].size < 15728640) {
      setFile({ type: "input", data: e.target.files[0] });
    } else {
      toast.error("動画の容量は15MBでお願いします。");
    }
  };
  const submit = () => {
    if (file) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file.data, file.data.name);
      axios
        .post(`${env.apiURL}ai-response/upload-file`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setIsLoading(false);
          setFile();
          setInputKey(new Date().getTime());
          setAiResponse(res.data);
          toast.success("動画のアップロードが成功でした。");
        })
        .catch((error) => {
          toast.error(error.data?.message ? error.data?.message : "AI分析途中エラーがありました。");
          setIsLoading(false);
        });
    }
  };
  return (
    <>
      <div className="w-full mb-12 2xl:mb-20">
        <div className="w-[86%] 2xl:w-[1080px] h-full mx-auto mt-28 2xl:mt-2">
          <div className="text-sm 2xl:text-base mt-4">映像アップページ</div>
          <div className="w-full text-center mt-6 2xl:mt-12 text-lg 2xl:text-2xl">逆上がりを成功させたい！</div>
          <div className="flex flex-col items-center 2xl:flex-row 2xl:justify-center text-xl 2xl:text-4xl mt-6 2xl:mt-12">
            <div className="relative">
              Step 1.
              <div className="absolute h-4 bottom-0 left-0 right-0 2xl:bg-hightLight z-[-1]" />
            </div>
            <div className="pl-3 relative">
              分析したい動画を撮る or 選ぶ
              <div className="absolute h-4 bottom-0 left-0 right-0 bg-hightLight z-[-1]" />
            </div>
          </div>
        </div>
        <div className="w-full 2xl:w-[1080px] mx-auto flex flex-wrap 2xl:items-end mt-8 2xl:mt-14 2xl:relative">
          <div className="w-[86%] 2xl:w-1/3 2xl:h-52 flex justify-center items-center z-10 mx-auto">
            <div className="relative pt-[56.25%] w-full 2xl:pt-[45%] 2xl:w-[80%]">
              <iframe
                src="https://www.youtube.com/embed/T0U2-6UvRIc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                className="absolute top-0 left-0 w-full h-full"
              />
            </div>
          </div>
          <div className="mt-4 w-full 2xl:w-2/3 z-10">
            <div className="w-full 2xl:w-[95%] mx-auto bg-primaryColor h-12 text-white flex justify-center 2xl:justify-start 2xl:pl-6 2xl:ml-[5%] items-center">
              動画撮影のポイントは３つ！
            </div>
            <div className="w-[86%] 2xl:w-[95%] ml-[5%] my-4">
              正しいアドバイスを受けるには動画撮影のコツがあります！　以下のポイントにしたがって、撮影をしてください。
            </div>
            <div className="w-full h-52 flex flex-col justify-evenly bg-blueCustom 2xl:bg-transparent rounded-md 2xl:rounded-xl px-[5%] 2xl:pl-[5%] 2xl:pr-0">
              <div className="w-full h-10 p-2 bg-white rounded-md 2xl:text-xl flex items-center">
                Point1　右斜め前から全身が映るように撮影
              </div>
              <div className="w-full h-10 p-2 bg-white rounded-md 2xl:text-xl flex items-center">
                Point2　逆上がりのチャレンジは１回
              </div>
              <div className="w-full h-10 p-2 bg-white rounded-md 2xl:text-xl flex items-center">
                Point3　動画は10秒以内
              </div>
            </div>
          </div>
          <div className="absolute w-[110%] h-52 bottom-0 left-[-5%] bg-grayCustom z-0 rounded-xl hidden 2xl:block" />
        </div>
        <div className="text-[12px] 2xl:text-[15px] text-[#828282] w-[86%] max-w-[800px] mx-auto text-center mt-8 font-medium">
          ※「動画を撮る」機能を使用する際にカメラのアクセスを許可してください。またAndroidとWindowsをお使いの方はChrome（89バージョン以上）、iPhoneとMacをお使いの方はSafari（14.5バージョン以上）で使用可能です。
        </div>
        <input
          className={css`
            visibility: hidden;
            width: 0;
            height: 0;
          `}
          ref={inputRef}
          key={inputKey}
          type="file"
          onChange={onChange}
          accept="video/*"
        />
        <div className="w-[86%] 2xl:w-[1080px] flex flex-col 2xl:flex-row 2xl:justify-evenly mx-auto text-white mt-4 2xl:mt-8">
          <div
            className="w-full 2xl:w-[30%] h-14 bg-primaryColor rounded-md 2xl:rounded-xl flex justify-center items-center mt-6 cursor-pointer"
            onClick={() => setVisiable(true)}
          >
            動画を撮る
          </div>
          <div
            className="w-full 2xl:w-[30%] h-14 bg-primaryColor rounded-md 2xl:rounded-xl flex justify-center items-center mt-6 cursor-pointer"
            onClick={() => inputRef.current.click()}
          >
            動画を選ぶ
          </div>
        </div>
        {file && (
          <div className="w-[86%] 2xl:w-[1080px] text-center mt-8 2xl:mt-14 mx-auto">
            動画が選択されました。下記から動画をアップロードしてください。
          </div>
        )}
        <div className="w-[86%] 2xl:w-[1080px] text-center mt-8 2xl:mt-14 mx-auto flex">
          <img
            src="assets/icons/down.svg"
            alt=""
            className="w-10 h-10 m-auto"
          />
        </div>
        <div className="flex flex-col items-center 2xl:flex-row 2xl:justify-center text-xl 2xl:text-4xl mt-8 2xl:mt-16">
          <div className="relative">
            Step 2.
            <div className="absolute h-4 bottom-0 left-0 right-0 2xl:bg-hightLight z-[-1]" />
          </div>
          <div className="pl-3 relative">
            動画をアップロード
            <div className="absolute h-4 bottom-0 left-0 right-0 bg-hightLight z-[-1]" />
          </div>
        </div>
        <div className="w-[86%] 2xl:w-[1080px] flex flex-col 2xl:flex-row 2xl:justify-center mx-auto text-white mt-8 2xl:mt-14">
          <div
            className={`w-full 2xl:w-[30%] h-14 ${
              file ? "bg-primaryColor" : "bg-[#63A7EA]"
            } hover:bg-primaryColor rounded-md 2xl:rounded-xl flex justify-center items-center cursor-pointer`}
            onClick={file ? submit : () => {}}
          >
            {isLoading ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                アップロード...
              </>
            ) : (
              "アップロード"
            )}
          </div>
        </div>
        <div className="w-[86%] 2xl:w-[1080px] text-center mt-6 2xl:mt-10 mx-auto">
          アップロードボタンをクリックすると１分程度でコーチAIからのアドバイスが届きます。
        </div>
        {aiResponse && (
          <>
            <div className="flex flex-col items-center 2xl:flex-row 2xl:justify-center text-xl 2xl:text-4xl mt-8 2xl:mt-16">
              <div className="relative">
                Step 3.
                <div className="absolute h-4 bottom-0 left-0 right-0 2xl:bg-hightLight z-[-1]" />
              </div>
              <div className="pl-3 relative">
                コーチAIのアドバイス
                <div className="absolute h-4 bottom-0 left-0 right-0 bg-hightLight z-[-1]" />
              </div>
            </div>
            <div className="w-[86%] 2xl:w-[1080px] mx-auto mt-4 2xl:mt-10 flex flex-wrap justify-between">
              <div className="w-full 2xl:w-[70%] border-[30px] border-blueCustom rounded-md relative mt-4">
                <video
                  src={aiResponse.videoPath}
                  type="video/mp4"
                  playsInline
                  webkitPlaysInline
                  autoPlay
                  loop
                  muted
                  controls
                  className="w-full"
                />
                <div className="absolute h-[30px] left-0 top-[-30px] flex justify-center items-center">
                  分析した動画
                </div>
              </div>
              <div className="w-full 2xl:w-[28%] flex flex-col">
                <div className="w-full border-[30px] border-blueCustom rounded-md relative mt-4 flex-1">
                  <div className="w-full h-[64px] 2xl:h-full flex justify-center items-center text-3xl">
                    {aiResponse.total}
                  </div>
                  <div className="absolute h-[30px] left-0 top-[-30px] flex justify-center items-center">得点</div>
                </div>
                <div className="w-full border-[30px] border-blueCustom rounded-md relative mt-4">
                  <img
                    className="w-full"
                    src={`${env.resultFolder}${aiResponse.chart}`}
                    alt=""
                  />
                  <div className="absolute h-[30px] left-0 top-[-30px] flex justify-center items-center">解析図</div>
                </div>
              </div>
              <div className="w-full flex flex-col 2xl:flex-row-reverse mt-8">
                <div className="w-full 2xl:w-[80%]">
                  <div
                    className={cx(
                      "2xl:ml-8 p-8 relative",
                      css`
                    width: 100%;
                    border: 2px solid #aeccff;
                    border-radius: 12px;
                    ::before{
                      position: absolute;
                      content: "";
                      background-color: #fff;
                      left: calc(50% - 24px);
                      bottom: -2px;
                      width: 48px;
                      height: 2px;
                    }
                    ::after {
                      position: absolute;
                      content: "";
                      background-color: #aeccff;
                      left: calc(50% - 29px);
                      bottom: -14px;
                      width: 34px;
                      height: 2px;
                      transform: rotate(45deg);
                    }
                    @media (min-width: 1024px) {
                      width: calc(100% - 32px);
                      border-radius: 12px;
                      ::before{
                        position: absolute;
                        content: "";
                        background-color: #fff;
                        top: 20%;
                        left: -2px;
                        height: 24px;
                        width: 2px;
                      }
                      ::after {
                        position: absolute;
                        content: "";
                        background-color: #aeccff;
                        top: calc(20% + 24px);
                        left: -34px;
                        height: 2px;
                        width: 32px;
                        transform: rotate(0);
                      }
                    }
                  }
                  `,
                    )}
                  >
                    {aiResponse.comment}
                    <div
                      className={css`
                        position: absolute;
                        left: calc(50% - 4px);
                        background-color: #aeccff;
                        bottom: -14px;
                        width: 34px;
                        height: 2px;
                        transform: rotate(-45deg);
                        @media (min-width: 1024px) {
                          content: "";
                          position: absolute;
                          background-color: #aeccff;
                          top: calc(20% + 11px);
                          left: -36px;
                          height: 2px;
                          width: 40px;
                          transform: rotate(-37deg);
                        }
                      `}
                    ></div>
                  </div>
                </div>
                <div className="w-full mt-6 2xl:mt-0 2xl:w-[20%]">
                  <img
                    src="/assets/icons/ai-manager.svg"
                    alt=""
                    className="w-[60%] 2xl:w-full mx-auto"
                  />
                </div>
              </div>
              <div
                className={`w-full mt-8 2xl:mt-14 2xl:w-[30%] h-14 text-white bg-primaryColor rounded-md 2xl:rounded-xl flex justify-center items-center cursor-pointer mx-auto`}
                onClick={() => setAiResponse()}
              >
                再チャレンジ！
              </div>
            </div>
          </>
        )}
      </div>
      {visiable && (
        <WebcamModal
          setVisiable={setVisiable}
          setFile={setFile}
          file={file}
        />
      )}
    </>
  );
};
export default Home;
