import { InstagramOutlined, TwitterOutlined, MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { css, cx } from "@emotion/css";
import { useState } from "react";
const Header = () => {
  const [visiable, setVisiable] = useState(false);
  return (
    <div className="fixed 2xl:static top-0 right-0 left-0 h-24 2xl:h-[150px] bg-primaryColor text-white z-50 2xl:z-10">
      <div className="w-[1080px] h-full mx-auto hidden 2xl:flex flex-col justify-between">
        <div className="h-[65px] pb-1 w-full flex justify-between items-end">
          <div className="text-xs 2xl:text-[17px] pb-2">スポーツ教育をサポート</div>
          <div className="flex justify-end items-center mb-[-3px]">
            <a
              href="https://www.facebook.com/spogasha"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/assets/icons/logo_facebook.svg"
                alt=""
                className="w-[38px] h-[38px] inline-block"
              />
            </a>
            <a
              href="https://www.instagram.com/spogasha/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramOutlined
                className={cx(
                  "w-[34px] h-[34px] mx-3",
                  css`
                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  `,
                )}
              />
            </a>
            <a
              href="https://twitter.com/Spo__Gasha"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterOutlined
                className={cx(
                  "w-[34px] h-[34px] ml-2",
                  css`
                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  `,
                )}
              />
            </a>
          </div>
        </div>
        <div className="h-[85px] w-full flex justify-between">
          <div className="h-full flex flex-col justify-center">
            <a
              href="https://spogasha.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/assets/icons/brand.svg"
                alt=""
                className="w-[283px]"
              />
            </a>
            <div className="w-full flex justify-end items-start h-6 text-[17px] my-1">スポガシャ !</div>
          </div>
          <div className="h-full flex-1">
            <div className="w-full pl-[20%] h-full flex justify-between items-center text-lg">
              <a
                href="https://spogasha.com/about/"
                target="_blank"
                rel="noreferrer"
              >
                Spo Gashaについて
              </a>
              <a
                href="https://spogasha.com/student/"
                target="_blank"
                rel="noreferrer"
              >
                生徒の方へ
              </a>
              <a
                href="https://spogasha.com/teacher/"
                target="_blank"
                rel="noreferrer"
              >
                スポーツ教育関係者の方へ
              </a>
              <a href="mailto:contact@research-coordinate.co.jp">お問い合わせ</a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[86%] h-full flex 2xl:hidden justify-between items-center mx-auto">
        <div className="h-full flex flex-col">
          <div className="text-[11px] h-[40px] flex items-end">スポーツ教育をサポート</div>
          <div className="h-[56px] pt-[2px] flex flex-col">
            <a
              href="https://spogasha.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/assets/icons/brand.svg"
                alt=""
                className="w-[151px]"
              />
            </a>
            <div className="w-full text-[11px] flex justify-end items-start">スポガシャ !</div>
          </div>
        </div>
        <MenuOutlined
          className={cx(
            "w-14 h-14",
            css`
              svg {
                width: 100%;
                height: 100%;
              }
            `,
          )}
          onClick={() => {
            setVisiable(true);
          }}
        />
        {visiable && (
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-primaryColor">
            <div className="w-[86%] mx-auto">
              <div className="h-24 flex justify-between items-center">
                <div className="h-full flex flex-col">
                  <div className="text-[14px] h-[34px] flex items-end">スポーツ教育をサポート</div>
                  <div className="h-[54px] pt-[2px] flex flex-col">
                    <a
                      href="https://spogasha.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/icons/brand.svg"
                        alt=""
                        className="h-[30px]"
                      />
                    </a>
                    <div className="w-full text-[12px] flex justify-end items-start">スポガシャ!</div>
                  </div>
                </div>
                <CloseOutlined
                  className={cx(
                    "w-10 h-10",
                    css`
                      svg {
                        width: 100%;
                        height: 100%;
                      }
                    `,
                  )}
                  onClick={() => {
                    setVisiable(false);
                  }}
                />
              </div>
              <div className="pl-4 mt-8 text-lg">
                <a
                  className="block py-[2px]"
                  href="https://spogasha.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  &gt; トップページ
                </a>
                <a
                  className="block py-[2px]"
                  href="https://spogasha.com/about/"
                  target="_blank"
                  rel="noreferrer"
                >
                  &gt; Spo Gashaについて
                </a>
                <a
                  className="block py-[2px]"
                  href="https://spogasha.com/student/"
                  target="_blank"
                  rel="noreferrer"
                >
                  &gt; 生徒の方へ
                </a>
                <a
                  className="block py-[2px]"
                  href="https://spogasha.com/teacher/"
                  target="_blank"
                  rel="noreferrer"
                >
                  &gt; スポーツ関係者の方へ
                </a>
                <a
                  className="block py-[2px]"
                  href="mailto:contact@research-coordinate.co.jp"
                  target="_blank"
                  rel="noreferrer"
                >
                  &gt; お問い合わせ
                </a>
                <a
                  className="block py-[2px]"
                  href="https://spogasha.com/news/"
                  target="_blank"
                  rel="noreferrer"
                >
                  &gt; ニュース
                </a>
                <a
                  className="block py-[2px]"
                  href="https://spogasha.com/blog/"
                  target="_blank"
                  rel="noreferrer"
                >
                  &gt; 活動のブログ
                </a>
                <a
                  className="block py-[2px]"
                  href="https://spogasha.com/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  &gt; プライバシーポリシー
                </a>
              </div>
              <div className="h-10 mt-4 pl-4 flex items-center">
                <a href="https://www.facebook.com/spogasha">
                  <img
                    src="/assets/icons/logo_facebook.svg"
                    alt=""
                    className="w-[40px] h-[40px] inline-block"
                  />
                </a>
                <a href="https://www.instagram.com/spogasha/">
                  <InstagramOutlined
                    className={cx(
                      "w-9 h-9 mx-1",
                      css`
                        svg {
                          width: 100%;
                          height: 100%;
                        }
                      `,
                    )}
                  />
                </a>
                <a href="https://twitter.com/Spo__Gasha">
                  <TwitterOutlined
                    className={cx(
                      "w-9 h-9 mx-1",
                      css`
                        svg {
                          width: 100%;
                          height: 100%;
                        }
                      `,
                    )}
                  />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Header;
