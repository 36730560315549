import { css, cx } from "@emotion/css";

const PageNotFound = () => {
  return (
    <div
      className={cx(
        "w-full h-screen",
        css`
          background-image: url("/assets/icons/page_not_found.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          background-origin: content-box;
          padding-left: 5%;
          padding-right: 5%;
          @media (min-width: 1024px) {
            padding-bottom: 10%;
          }
        `,
      )}
    ></div>
  );
};
export default PageNotFound;
