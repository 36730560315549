import { Outlet, useNavigate } from "react-router-dom";
import Footer from "./footer";
import Header from "./header";
import { useEffect } from "react";

const Layout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/pullover");
  }, []);
  return (
    <div className="w-full">
      <Header />
      <div className="text-primaryColor">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
export default Layout;
