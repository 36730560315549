import { InstagramOutlined, TwitterOutlined } from "@ant-design/icons";
import { css, cx } from "@emotion/css";
const Footer = () => {
  return (
    <div className="w-full bg-primaryColor text-white pt-4 pb-12">
      <div className="w-full 2xl:w-[1080px] h-full mx-auto flex 2xl:flex-row flex-wrap justify-between">
        <div className="w-full 2xl:w-1/2">
          <div className="flex flex-col">
            <div className="flex justify-center 2xl:justify-between">
              <div className="h-24 2xl:h-28 flex flex-col">
                <div className="text-[14px] 2xl:text-xs h-[34px] 2xl:h-12 pb-1 flex justify-between items-end">
                  スポーツ教育をサポート
                </div>
                <div className="h-[54px] 2xl:h-16 pt-[2px] flex flex-col">
                  <a
                    href="https://spogasha.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/icons/brand.svg"
                      alt=""
                      className="h-[30px] 2xl:h-10"
                    />
                  </a>
                  <div className="w-full text-xs flex justify-end items-start">スポガシャ!</div>
                </div>
              </div>
              <div />
            </div>
            <div className="w-full mt-2 2xl:mt-6">
              <div className="w-full flex justify-center 2xl:justify-start">
                <a
                  href="https://spogasha.com/company/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-xs"
                >
                  会社概要
                </a>
              </div>
              <div className="h-10 flex justify-center 2xl:justify-start items-center mt-2">
                <a
                  href="https://www.facebook.com/spogasha"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/icons/logo_facebook.svg"
                    alt=""
                    className="w-[26px] h-[26px] inline-block"
                  />
                </a>
                <a
                  href="https://www.instagram.com/spogasha/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramOutlined
                    className={cx(
                      "w-6 h-6 mx-1",
                      css`
                        svg {
                          width: 100%;
                          height: 100%;
                        }
                      `,
                    )}
                  />
                </a>
                <a
                  href="https://twitter.com/Spo__Gasha"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterOutlined
                    className={cx(
                      "w-6 h-6 mx-1",
                      css`
                        svg {
                          width: 100%;
                          height: 100%;
                        }
                      `,
                    )}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full 2xl:w-1/2 flex justify-center text-center 2xl:text-start 2xl:justify-end text-sm mt-5">
          <div className="">
            <a
              className="block py-[2px]"
              href="https://spogasha.com/"
              target="_blank"
              rel="noreferrer"
            >
              トップページ
            </a>
            <a
              className="block py-[2px]"
              href="https://spogasha.com/about/"
              target="_blank"
              rel="noreferrer"
            >
              Spo Gashaについて
            </a>
            <a
              className="block py-[2px]"
              href="https://spogasha.com/student/"
              target="_blank"
              rel="noreferrer"
            >
              生徒の方へ
            </a>
            <a
              className="block py-[2px]"
              href="https://spogasha.com/teacher/"
              target="_blank"
              rel="noreferrer"
            >
              スポーツ関係者の方へ
            </a>
            <a
              className="block py-[2px]"
              href="mailto:contact@research-coordinate.co.jp"
              target="_blank"
              rel="noreferrer"
            >
              お問い合わせ
            </a>
            <a
              className="block py-[2px]"
              href="https://spogasha.com/news/"
              target="_blank"
              rel="noreferrer"
            >
              ニュース
            </a>
            <a
              className="block py-[2px]"
              href="https://spogasha.com/blog/"
              target="_blank"
              rel="noreferrer"
            >
              活動のブログ
            </a>
            <a
              className="block py-[2px]"
              href="https://spogasha.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              プライバシーポリシー
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
