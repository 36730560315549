import { CloseOutlined } from "@ant-design/icons";
import { css, cx } from "@emotion/css";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import { RecordRTCPromisesHandler } from "recordrtc";

export const WebcamModal = ({ setVisiable, setFile, file }) => {
  const modalRef = useRef(null);
  const webcamRef = useRef(null);
  const [isReady, setIsReady] = useState(false);
  const mediaRecorderRef = useRef(null);
  const [isStart, setIsStart] = useState("none");
  const handleRestartCaptureClick = () => {
    if (file && file.type === "webcam") {
      setFile();
      setIsStart("start");
      mediaRecorderRef.current = new RecordRTCPromisesHandler(webcamRef.current.stream, {
        type: "video",
        mimeType: 'video/mp4; codecs="mpeg4, aac"',
      });
      mediaRecorderRef.current.startRecording();
    }
  };
  const handleStartCaptureClick = () => {
    if (!(file && file.type === "webcam")) {
      setIsStart("start");
      mediaRecorderRef.current = new RecordRTCPromisesHandler(webcamRef.current.stream, {
        type: "video",
        mimeType: 'video/mp4; codecs="mpeg4, aac"',
      });
      mediaRecorderRef.current.startRecording();
    }
  };

  const handleStopCaptureClick = async () => {
    await mediaRecorderRef.current.stopRecording();
    let blob = await mediaRecorderRef.current.getBlob();
    setIsStart("done");
    if (blob.size < 15728640) {
      setFile({ type: "webcam", data: blob });
    } else {
      toast.error("動画の容量は15MBでお願いします。");
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setVisiable(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex bg-[#0004] z-50">
      <div
        className="m-auto w-[86%] 2xl:w-[40%] bg-white z-20 rounded-xl"
        ref={modalRef}
      >
        <div className="flex justify-between items-center h-16 px-[5%]">
          <div className="text-lg flex-1">動画を撮る</div>
          <CloseOutlined
            className={cx(
              "w-6 h-6",
              css`
                svg {
                  width: 100%;
                  height: 100%;
                }
              `,
            )}
            onClick={() => {
              setVisiable(false);
            }}
          />
        </div>
        <div className="w-full mt-2 px-[5%]">
          <Webcam
            ref={webcamRef}
            onUserMedia={() => setIsReady(true)}
            crossOrigin="anonymous"
            videoConstraints={{
              facingMode: "environment",
            }}
            className="w-full rounded-md xsm:rounded-xl"
          />
        </div>
        <div className="w-full flex my-4 justify-between px-[5%] text-white">
          <div
            className={`${
              file && file.type === "webcam" ? "bg-primaryColor cursor-pointer" : "bg-grayCustom"
            } w-[47%] h-14 rounded-md 2xl:rounded-xl flex justify-center items-center`}
            onClick={handleRestartCaptureClick}
          >
            やり直す
          </div>
          <div
            className="w-[47%] h-14 bg-primaryColor rounded-md 2xl:rounded-xl flex justify-center items-center cursor-pointer"
            onClick={
              !isReady
                ? () => {}
                : isStart === "start"
                ? handleStopCaptureClick
                : isStart === "none"
                ? handleStartCaptureClick
                : () => setVisiable(false)
            }
          >
            {isStart === "start" ? "撮影終了" : isStart === "none" ? "撮影開始" : "OK"}
          </div>
        </div>
      </div>
    </div>
  );
};
