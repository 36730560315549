import React from "react";
import { unstable_HistoryRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "../app/Home";
import PageNotFound from "../app/PageNotFound";
import Layout from "../components/Layout";
import history from "../utils/history";

const AppRouter = () => {
  return (
    <div className="font-bold text-textColor">
      <Router history={history}>
        <Routes>
          <Route
            element={<Layout />}
            path="/"
          >
            <Route
              element={<Home />}
              path="pullover"
            />
          </Route>
          <Route
            element={<PageNotFound />}
            path="*"
          />
        </Routes>
      </Router>
      <ToastContainer
        autoClose={2000}
        position="top-center"
      />
    </div>
  );
};
export default AppRouter;
